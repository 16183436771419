import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout, retry, catchError, finalize } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';

// Providers
import { OverlayService } from './overlay.service';
import { TokenStorage } from './token-storage.service';
import { LoaderService } from '../../shared/components/loader/services/loader.service';
import { Tools } from '../helpers/tools';
import { RotinaResponse } from '../../shared/models/rotina-response.model';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	private readonly defaultTimeOut: number = 30000;
	private readonly defaultRetry: number = 3;

	constructor(private http: HttpClient,
		private overlayService: OverlayService,
		private tokenStorage: TokenStorage,
		private loaderService: LoaderService) {

	}

	private getToken(): string {
		return this.tokenStorage.getAccessToken();
	}

	get(URL: string, params?: { [key: string]: any }): Observable<any> {
		const header: any = {
			headers: new HttpHeaders({ Token: this.getToken() }),
		};

		if (params) {
			header.params = params;
		}

		return this.http.get<any>(URL, header).pipe(
			timeout(this.defaultTimeOut),
			retry(this.defaultRetry),
			catchError((reason) => {
				this.controlaToast(reason);
				this.loaderService.dismissAll();
				return EMPTY;
			})
		);
	}

	post(URL: string, body: any, params?: { [key: string]: any }, showLoader: boolean = true): Observable<any> {
		const header: any = {
			headers: new HttpHeaders({ Token: this.getToken() }),
		};

		if (params) {
			header.params = params;
		}

		if (showLoader) {
			this.loaderService.show();
		}

		return this.http.post<any>(URL, body, header).pipe(
			timeout(this.defineTimeout(this.defaultTimeOut, URL, 0)),
			catchError((reason) => {
				this.controlaToast(reason);
				this.loaderService.dismissAll();
				return EMPTY;
			}),
			finalize(() => {
				this.loaderService.hide();
			})
		);
	}

	async postAsync(URL: string, body: any, params?: { [key: string]: any }, showLoader: boolean = true): Promise<any> {
		const header: any = {
		  headers: new HttpHeaders({ Token: this.getToken() })
		};
	
		if (params) {
		  header.params = params;
		}
	
		if (showLoader) {
		  this.loaderService.show();
		}
	
		try {
			const result = await this.http.post<any>(URL, body, header).pipe(
				timeout(this.defineTimeout(this.defaultTimeOut, URL, 0)),
				catchError((reason) => {
					this.controlaToast(reason);
					this.loaderService.dismissAll();
					return EMPTY;
				})).toPromise();
			
			return result;
		} catch (error) {
			this.controlaToast(error);
			this.loaderService.dismissAll();
			return EMPTY;
		} finally {
			this.loaderService.dismissAll();
		  	this.loaderService.hide();
		}
	}

	put(URL: string, body: any, params?: any, showLoader: boolean = true): Observable<any> {
		const header: any = {
			headers: new HttpHeaders({ Token: this.getToken() }),
		};

		if (params) {
			header.params = params;
		}

		if (showLoader) {
			this.loaderService.show();
		}

		return this.http.put(URL, body, header).pipe(
			timeout(this.defineTimeout(this.defaultTimeOut, URL, 0)),
			catchError((reason) => {
				this.controlaToast(reason);
				this.loaderService.dismissAll();
				return EMPTY;
			}),
			finalize(() => {
				this.loaderService.hide();
			})
		);

	}

	delete(URL: string, params?: any): Observable<any> {
		const header: any = {
			headers: new HttpHeaders({ Token: this.getToken() }),
		};

		if (params) {
			header.params = params;
		}

		return this.http.delete(URL, header).pipe(
			timeout(this.defaultTimeOut),
			catchError((reason) => {
				this.controlaToast(reason);
				this.loaderService.dismissAll();
				return EMPTY;
			})
		);
	}

	private defineTimeout(defaultTimeOut: number, url: string, segundosAcrescentar: number): number {
		if (url.toLowerCase().indexOf('ptc0001') > -1 || url.toLowerCase().indexOf('ope0001') > -1 || url.toLowerCase().indexOf('ose0001') > -1 ||
			url.toLowerCase().indexOf('apo0002') > -1 || url.toLowerCase().indexOf('apo0003') > -1) {
			defaultTimeOut = 60000;
		}
		if (url.toLowerCase().indexOf('sped') > -1 && url.toLowerCase().indexOf('0001') > -1) {
			defaultTimeOut = 7200000;
		}
		if ((url.toLowerCase().indexOf('sintegra') > -1 || url.toLowerCase().indexOf('rps') > -1 || url.toLowerCase().indexOf('remessa') > -1) &&
			(url.toLowerCase().indexOf('0001') > -1 || url.toLowerCase().indexOf('email') > -1 || url.toLowerCase().indexOf('boleto') > -1)) {
			defaultTimeOut = 300000;
		}
		if ((url.toLowerCase().indexOf('dief') > -1 || url.toLowerCase().indexOf('dctf') > -1) && url.toLowerCase().indexOf('0001') > -1) {
			defaultTimeOut = 600000;
		}
		if (url.toLowerCase().indexOf('relatorio') > -1) {
			defaultTimeOut = 300000;
		}
		if (url.toLowerCase().indexOf('finalizar') > -1 || url.toLowerCase().indexOf('estornar') > -1 || url.toLowerCase().indexOf('recontabilizar') > -1 ||
			url.toLowerCase().indexOf('retorno') > -1 || url.toLowerCase().indexOf('devolucao') > -1 || url.toLowerCase().indexOf('cancelar') > -1 ||
			url.toLowerCase().indexOf('complementar') > -1 || url.toLowerCase().indexOf('email') > -1 || url.toLowerCase().indexOf('importar') > -1 ||
			url.toLowerCase().indexOf('baixar') > -1 || url.toLowerCase().indexOf('contabilizar') > -1 || url.toLowerCase().indexOf('encerramento') > -1) {
			defaultTimeOut = 300000;
		}
		if (url.toLowerCase().indexOf('calcular') > -1 || (url.toLowerCase().indexOf('ptc0003') > -1 && url.toLowerCase().indexOf('copiar') > -1) ||
			(url.toLowerCase().indexOf('enviar') > -1 && url.toLowerCase().indexOf('nfse') > -1) ||
			(url.toLowerCase().indexOf('consultar') > -1 && url.toLowerCase().indexOf('nfe') > -1 && url.toLowerCase().indexOf('dest') > -1)) {
			defaultTimeOut = 180000;
		}
		if (url.toLowerCase().indexOf('nfse') > -1 &&
			(url.toLowerCase().indexOf('enviar') > -1 || url.toLowerCase().indexOf('consultar') > -1 ||
			 url.toLowerCase().indexOf('cancelar') > -1 || url.toLowerCase().indexOf('rps') > -1)) {
			defaultTimeOut = 7200000;
		}

		if (segundosAcrescentar > 0) {
			defaultTimeOut = defaultTimeOut + segundosAcrescentar;
		}

		return defaultTimeOut;
	}

	private controlaToast(reason: any){
		if (this.isRotinaResponse(reason)) {
			this.overlayService.persistenteRotinaResponseToast({ message: Tools.tratarRetornoAPI(reason) }, reason);
		} else {
			if (this.isRegraNegocioExcecao(reason)) {
				this.overlayService.persistenteRotinaResponseToast({ message: Tools.tratarRetornoAPI(reason) });
			} else {
				this.overlayService.toast({ message: Tools.tratarRetornoAPI(reason) });
			}
		}
	}

	private isRotinaResponse(reason: any): boolean {	
		let retorno: boolean = false;
	  
		// Verificar se reason e um objeto com a propriedade Sucesso definida
		if (reason && reason.Sucesso !== undefined) {
			retorno = true;
		}
		return retorno;
	}

	private isRegraNegocioExcecao(reason: any): boolean {
		let mensagem: string = Tools.manipularRetornoAPI(reason);
		let retorno: boolean = false;
	  
		// Verificar se é uma mensagem de erro de validação
		if (reason && reason.error !== undefined && reason.message !== undefined && reason.message.indexOf('Http failure response') > -1) {
			return true;
		}
	  
		// Verificar se reason contem <br />
		if (mensagem !== undefined && mensagem.indexOf('<br />') > -1) {
			return true;
		}
	  
		return retorno;
	}
}
